import { render, staticRenderFns } from "./ProcessList.vue?vue&type=template&id=67945bb9"
import script from "./ProcessList.vue?vue&type=script&lang=tsx"
export * from "./ProcessList.vue?vue&type=script&lang=tsx"
import style0 from "./ProcessList.vue?vue&type=style&index=0&id=67945bb9&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports